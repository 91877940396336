import { useEffect, useMemo } from "react";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { FLAGGING_SERVICE } from "src/configs/featuresFlag";
import { SERVICE } from "src/configs/service";
import { useList } from "src/hooks";
import { IUseListReturnValue } from "src/hooks/dataProvider/type";
import { useFlagging } from "src/hooks/flagging";
import { IPromoData } from "src/interface/promo.model";

import { MobileLayout } from "../../../../components";
import { IPromoKuListPageProps, PromoList, PromoListV2 } from "../..";
import {
  nonAuthorizedPromoCard,
  nonAuthorizedPromoCardV2,
} from "../../constants";
import { trackPromoKuLoaded } from "./tracker";

export default function PromoKuPageNonAuthorized({
  headerProps,
  footerProps,
  chakra,
  resourceProps,
  onPromoClick,
  useOuterContainer,
  useFragmentWrapper,
  flagPromoKuRevamp,
}: IPromoKuListPageProps) {
  const { data: flaggingData } = useFlagging([
    {
      flag: FLAGGING_SERVICE.FEATURE_PROMO_REVAMP_V2,
      defaultValue: { variantKey: "off" },
    },
  ]);
  const [FEATURE_PROMOKUV2] = flaggingData;

  const isPromoKuRevamp = useMemo(() => {
    // for testing purpose
    if (flagPromoKuRevamp !== undefined) return flagPromoKuRevamp;
    return FEATURE_PROMOKUV2.variantKey === "on";
  }, [FEATURE_PROMOKUV2]);

  const queryResults: IUseListReturnValue<IPromoData> = {
    data: {
      data: isPromoKuRevamp
        ? nonAuthorizedPromoCardV2
        : nonAuthorizedPromoCard,
      total: 1,
    },
    isSuccess: true,
    isError: true,
    isLoading: false,
    refetch: () => {},
  };

  const queryResultsPublicPromos = useList<IPromoData>({
    apiUrl: SERVICE.PROMO_V2,
    resource: "v1/publics/promos",
    meta: {
      ...resourceProps?.meta,
      params: {
        ...resourceProps?.meta?.params,
        promo_category: "physical-prize",
      },
    },
    ...(isPromoKuRevamp &&
    resourceProps?.meta?.headers?.["X-Client-Id"] !== undefined
      ? {}
      : {
          pagination: {
            mode: "off",
          },
        }),
  });

  if (resourceProps?.gtmID) {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: resourceProps?.gtmID || "",
    };
    TagManager.initialize(tagManagerArgs);
  }

  useEffect(() => {
    if (resourceProps?.gtmID) {
      trackPromoKuLoaded("");
    }
  }, [resourceProps?.gtmID]);

  return (
    <MobileLayout
      chakra={chakra?.layout}
      headerProps={headerProps}
      footerProps={footerProps}
      useOuterContainer={useOuterContainer}
      useFragmentWrapper={useFragmentWrapper}
    >
      {isPromoKuRevamp ? (
        <PromoListV2
          queryResultsPromos={queryResults}
          queryResultsPublicPromos={queryResultsPublicPromos}
          onPromoClick={onPromoClick}
          chakra={chakra?.promoKuList}
        />
      ) : (
        <PromoList
          queryResults={queryResults}
          onPromoClick={onPromoClick}
          chakra={chakra?.promoKuList}
        />
      )}
    </MobileLayout>
  );
}
