import { useDisclosure } from "@efishery/onefish";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "src/components";
import { PromoDetailModal } from "src/features/PromoKu/components/PromoDetailModal";
import { IPromoData } from "src/interface/promo.model";

import PromoKuDetailsPage from "./PromoKuDetails";

export default function PromoKuDetailPage() {
  const navigate = useNavigate();
  const modalDisclosure = useDisclosure();
  const initialResourceProps = {
    Authorization: "",
    GTM_ID: "",
    "X-Client-Id": "efisheryku",
  };
  const [resourceProps, setResourceProps] = useState(initialResourceProps);
  const [path, setPath] = useState<{
    url: string;
    params: { key: string; value: string }[];
  }>({
    url: "",
    params: [],
  });

  const getUrlParams = (url: string) => {
    const newUrl = new URL(url);
    const params = new URLSearchParams(newUrl.search);
    const parameters: { key: string; value: string }[] = [];
    params.forEach((value, key) => parameters.push({ key, value }));
    setPath({
      url,
      params: parameters,
    });
    modalDisclosure.onOpen();
  };

  useEffect(() => {
    const authorizationToken = sessionStorage.getItem("dev_promoku_auth");
    const xClientId = sessionStorage.getItem("dev_promoku_clientid");
    const gtmId = sessionStorage.getItem("dev_promoku_gtmid");
    setResourceProps((prev) => ({
      ...prev,
      ...(authorizationToken && { Authorization: authorizationToken }),
      ...(xClientId && { "X-Client-Id": xClientId }),
      ...(gtmId && { GTM_ID: gtmId }),
    }));
  }, []);

  if (resourceProps.Authorization === "") {
    return <Loading />;
  }

  return (
    <>
      <PromoKuDetailsPage
        paramsId="promoId"
        headerProps={{
          transparent: true,
          onPreviousClick() {
            navigate(-1);
          },
        }}
        buttonProps={{
          lable: "Gunakan Promo",
          chakra: {
            button: {
              width: "90%",
            },
          },
        }}
        onUsePromo={(promoData: IPromoData) => {
          const defaultUrl = `https://stg-membership.efishery.com/beli-pakan?promo_id=${promoData.id}&promo_code=${promoData.code}&utm_source=belut-remote&utm_medium=click&utm_campaign=promoku`;
          const url =
            sessionStorage.getItem("dev_promoku_detail_targetUrl") ??
            defaultUrl;
          getUrlParams(url);
        }}
        resourceProps={{
          gtmID: resourceProps.GTM_ID,
          meta: {
            headers: {
              Authorization: resourceProps.Authorization,
              "X-Client-Id": resourceProps["X-Client-Id"],
            },
          },
        }}
        useOuterContainer
      />
      <PromoDetailModal modalDisclosure={modalDisclosure} path={path} />
    </>
  );
}
