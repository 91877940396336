import { IPromoData } from "src/interface/promo.model";

export const nonAuthorizedPromoCard: IPromoData[] = [
  {
    app_id: "fish-in-tools",
    code: "CONTOHPROMO",
    created_at: "2023-08-09T07:49:19.813Z",
    id: "someid",
    name: "Voucher Pengguna Baru",
    priority: 1,
    rules: {
      channels: ["efisheryku", "feedhub"],
      start_date: "2023-08-01T00:00:00.000Z",
      end_date: "",
      maximum_discount: 0,
      maximum_transaction_amount: 0,
      minimum_transaction_amount: 0,
      payment_methods: ["cash", "kabayan"],
      promo_amount: 0,
      promo_criteria: {
        items: null,
        price_lists: null,
        product_types: null,
        supplier_sources: null,
        supplier_types: null,
        suppliers: null,
      },
      promo_limit_qty: 9999,
      promo_limit_type: "user",
      promo_type: "mf_nonauthorized",
      promo_unit: "sack",
      segmentation: {
        method: "manual",
        phones: null,
        points: null,
        users: null,
      },
      transaction_unit: "sack",
      created_at: "0001-01-01T00:00:00.000Z",
      updated_at: "0001-01-01T00:00:00.000Z",
    },
    status: "published",
    term_and_condition: "<p>test</p>",
    updated_at: "2023-08-09T07:49:24.281Z",
    user_id: "356df449-2218-4a38-84ea-1e061ed54f80",
    user_type: "farmer",
  },
];

export const nonAuthorizedPromoCardV2: IPromoData[] = [
  {
    app_id: "",
    code: "",
    created_at: "",
    id: "",
    name: "Promo Khusus Pengguna Baru",
    priority: 1,
    rules: {
      channels: [""],
      start_date: "",
      end_date: "2024-12-31T00:00:00.000Z",
      maximum_discount: 0,
      maximum_transaction_amount: 0,
      minimum_transaction_amount: 0,
      payment_methods: [""],
      promo_amount: 0,
      promo_criteria: {
        items: null,
        price_lists: null,
        product_types: null,
        supplier_sources: null,
        supplier_types: null,
        suppliers: null,
      },
      promo_limit_qty: 1,
      promo_limit_type: "",
      promo_type: "",
      promo_unit: "",
      segmentation: {
        method: "",
        phones: null,
        points: null,
        users: null,
      },
      transaction_unit: "",
      created_at: "",
      updated_at: "",
    },
    status: "",
    term_and_condition: "",
    updated_at: "",
    user_id: "",
    user_type: "",
  },
];

export type ReferralErrorKeys =
  | "REFERRAL_NOT_VALID"
  | "CANNOT_USE_OWN_REFERRAL"
  | "REFERRAL_ALREADY_REGISTERED";

export const referralErrorMessages = {
  REFERRAL_NOT_VALID: "Kode Referral tidak valid",
  CANNOT_USE_OWN_REFERRAL: "Kamu tidak bisa memasukan kode referral sendiri",
  REFERRAL_ALREADY_REGISTERED: "Kode Referral sudah pernah digunakan",
};

export const codeInputMessage = {
  promo: {
    failed: "Kode Promo belum bisa dipakai",
    success: "Kode Promo berhasil dipakai",
  },
  referral: {
    failed: "Kode Referral belum bisa dipakai",
    success: "Kode Referral berhasil dipakai",
  },
};
