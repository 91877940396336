import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
} from "@efishery/onefish";
import dayjs from "dayjs";
import { FaClock } from "react-icons/fa6";
import DefaultPromoIMG from "src/assets/images/promo_v2_default_img.jpg";
import { ENV } from "src/configs/env";
import { IPromoCardProps } from "src/features";

export function PromoCardV2({ promoData, onPromoClick }: IPromoCardProps) {
  return (
    <Box w="full">
      {promoData?.remaining_promo && promoData?.remaining_promo > 1 ? (
        <Box
          top={0}
          px="16px"
          pt="12px"
          pb="20px"
          w="full"
          mb="-10px"
          borderTopRadius="10px"
          data-testid={`txt_promoCard-promoLimitQty-${promoData.id}`}
          bgGradient="linear(to-r, #A5A5A5 -0%, #D2D2D2 47%, #EEEDED 72%, #D2D2D2 100%)"
        >
          <Text fontSize="12px" fontWeight="500">
            Bisa dipakai {promoData?.remaining_promo} kali
          </Text>
        </Box>
      ) : null}
      <VStack
        flex={1}
        spacing={0}
        shadow="md"
        width="full"
        borderRadius={14}
        justifyContent="center"
      >
        <Box
          w="full"
          position="relative"
          _after={{
            content: '""',
            display: "block",
            backgroundColor: "white",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            right: "-10px",
            bottom: "-12px",
            position: "absolute",
            zIndex: 2,
            boxShadow: "inset 8px 0px 8px -5px rgb(0 0 0 / 10%)",
          }}
          _before={{
            content: '""',
            display: "block",
            backgroundColor: "white",
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            left: "-10px",
            bottom: "-12px",
            zIndex: 2,
            position: "absolute",
            boxShadow: "inset -8px 0px 8px -5px rgb(0 0 0 / 10%)",
          }}
        >
          <AspectRatio ratio={328 / 110}>
            <Image
              width="full"
              height="full"
              objectFit="cover"
              borderTopRadius={12}
              src={promoData?.image_url}
              fallbackSrc={`${ENV.APP_HOST}${DefaultPromoIMG}`}
              data-testid={`img_promoCard-banner-${promoData.id}`}
            />
          </AspectRatio>
        </Box>
        <VStack
          w="full"
          spacing={0}
          pt="12px"
          px="18px"
          pb="16px"
          minH="95px"
          borderTopRadius={12}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Text
            fontWeight="600"
            fontSize="16px"
            noOfLines={2}
            data-testid={`txt_promoCard-title-${promoData.id}`}
          >
            {promoData?.name}
          </Text>
          <HStack w="full" justifyContent="space-between" mt={2}>
            <HStack spacing={1.5}>
              <FaClock color="#4A4A4A" />
              <Text
                fontSize="12px"
                color="gray.600"
                fontWeight="500"
                data-testid={`txt_promoCard-expiredTime-label-${promoData.id}`}
              >
                {`Berakhir ${dayjs
                  .tz(promoData?.rules?.end_date, "Asia/Jakarta")
                  .locale("id")
                  .format("DD/MM/YYYY")}`}
              </Text>
            </HStack>
            <Button
              size="sm"
              fontWeight="500"
              variant="outline"
              onClick={() => onPromoClick?.(promoData)}
              data-testid={`btn_promoCard-goToDetail-${promoData.id}`}
            >
              Cek Detail
            </Button>
          </HStack>
        </VStack>
      </VStack>
    </Box>
  );
}
