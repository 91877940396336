/** 
 Give to flagID from flagging service
 Recommended to add all the environment
 e.g. [staging, uat, productio]

 if you only provide one flagID on the array,
 we assume you provide a staging environment flagId
 so,the other flagID (uat and production) will behandled by useFlagging hooks
 */
export const FLAGGING_SERVICE = {
  FEATURE_PROMO_REVAMP_V2: [2212, 2213, 2214],
  FEATURE_PROMOKU_REFERRAL: [2386, 2387, 2388],
};
