import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@efishery/onefish";
import dayjs from "dayjs";
import { useState } from "react";
import { BsHourglassSplit } from "react-icons/bs";
import { PiTagLight } from "react-icons/pi";
import OldDefaultPromoIMG from "src/assets/images/img_default.svg";
import DefaultPromoIMG from "src/assets/images/promo_v2_default_img.jpg";
import { Loading } from "src/components";
import { ENV } from "src/configs/env";
import { encodeString } from "src/utils/string";

import { IPromoKuDetailProps } from "../..";
import { promoCardDescription } from "../../utils";
import { TransactionLimit } from "./TransactionLimit";

export function PromoDetail({
  promoData,
  isLoading,
  chakra,
  buttonProps,
  onUsePromo,
  flaggingData = [],
}: IPromoKuDetailProps) {
  const [FEATURE_PROMOKUV2] = flaggingData;
  const [descSection, setDescSection] = useState({ isExtended: false });
  const useRevampV2 = FEATURE_PROMOKUV2?.variantKey === "on";
  const remainingUsage = promoData?.remaining_promo || 1;
  const isTermAndConditionAList =
    promoData?.term_and_condition?.includes("<li>");
  const isPromoDiscount = promoData?.rules?.promo_category === "discount";

  const periode = useRevampV2
    ? `${dayjs
        .utc(promoData?.rules?.end_date)
        .tz("Asia/Jakarta")
        .locale("id")
        .format("DD/MM/YYYY")}`
    : `${dayjs
        .utc(promoData?.rules?.end_date)
        .tz("Asia/Jakarta")
        .locale("id")
        .format("DD MMMM YYYY - HH.mm")} WIB`;

  const renderTermAndCondition = () => {
    if (!promoData || !isTermAndConditionAList) {
      return (
        <Box
          dangerouslySetInnerHTML={{
            __html: promoData?.term_and_condition ?? "",
          }}
          width="full"
          fontSize="sm"
          data-testid="txtarea_promoku-detail-description"
        />
      );
    } else {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        promoData?.term_and_condition,
        "text/html"
      );
      const listItem = Array.from(doc.querySelectorAll("li"));
      const result = listItem.map((item) => item.textContent?.trim());
      return (
        <VStack alignItems="flex-start" spacing="20px">
          {result.map((item, index) => (
            <HStack key={index} spacing="8px">
              <Box p="8px" backgroundColor="brand.50" borderRadius="4px">
                <PiTagLight color="#038767" size="24px" />
              </Box>
              <Text fontSize="sm" fontWeight="500">
                {item}
              </Text>
            </HStack>
          ))}
        </VStack>
      );
    }
  };

  return (
    <VStack
      width="full"
      boxShadow="sm"
      minHeight="100vh"
      spacing="0"
      position="relative"
      {...chakra?.container}
    >
      <Loading
        container={{
          position: "absolute",
          height: "full",
          width: "full",
          flexGrow: "1",
          opacity: isLoading ? ".8" : "0",
          transition: ".5s all ease",
          zIndex: isLoading ? "1" : "-1",
        }}
        spinner={{
          size: "xl",
          color: "brand.500",
        }}
      />
      <VStack
        width="full"
        padding="0"
        spacing="0"
        boxShadow="md"
        bg="white"
        opacity={isLoading ? "0" : "1"}
        transition=".5s all ease"
        marginBottom="14px !important"
      >
        <VStack position="relative" w="full">
          {useRevampV2 ? (
            <AspectRatio ratio={328 / 128} width="full" maxH={160}>
              <Image
                src={promoData?.image_url}
                fallbackSrc={`${ENV.APP_HOST}${DefaultPromoIMG}`}
                width="full"
                objectFit="cover"
                data-testid="img_promoku-detail-header-image"
              />
            </AspectRatio>
          ) : (
            <Image
              src={promoData?.image_url}
              fallbackSrc={`${ENV.APP_HOST}${OldDefaultPromoIMG}`}
              width="full"
              height="200px"
              objectFit="cover"
              data-testid="img_promoku-detail-header-image"
            />
          )}
        </VStack>

        {useRevampV2 && remainingUsage > 1 && (
          <Box
            py="12px"
            pl="16px"
            w="full"
            data-testid={`txt_promoDetail-promoLimitQty-${promoData?.id}`}
            bgGradient="linear(to-r, #A5A5A5 -0%, #D2D2D2 47%, #EEEDED 72%, #D2D2D2 100%)"
          >
            <Text fontSize="12px" fontWeight="500">
              Bisa dipakai {remainingUsage} kali
            </Text>
          </Box>
        )}

        <VStack width="full" borderRadius="0 0 10px 10px" padding="14px">
          <VStack spacing="10px" width="full">
            <Heading
              size="lg"
              alignSelf="flex-start"
              data-testid="txt_promoku-detail-title"
            >
              {promoData?.name}
            </Heading>
            <Flex justifyContent="space-between" width="full" columnGap="10px">
              <HStack>
                <Image
                  src=""
                  fallback={<BsHourglassSplit color="black" size="15px" />}
                  boxSize="30px"
                  data-testid="img_promoku-detail-expiredTime"
                />
                <VStack alignItems="flex-start" spacing="0">
                  <Text
                    fontSize="xs"
                    fontWeight="700"
                    color="grey.400"
                    data-testid="txt_promoku-detail-expiredTime-label"
                  >
                    Berakhir pada
                  </Text>
                  <Text
                    fontSize="xs"
                    data-testid="txt_promoku-detail-expiredTime-date"
                  >
                    {periode}
                  </Text>
                </VStack>
              </HStack>
              <TransactionLimit data={promoData} />
            </Flex>
            {!useRevampV2 && (
              <>
                <Divider />
                <Flex alignSelf="flex-start">
                  <Text fontSize="xs" data-testid="txt_promoku-detail-topic">
                    {promoCardDescription(promoData)}
                  </Text>
                </Flex>
              </>
            )}
          </VStack>
        </VStack>
      </VStack>
      <VStack
        width="full"
        spacing="20px"
        padding="14px"
        height="full"
        flexGrow="1"
        boxShadow="md"
        bg="white"
        opacity={isLoading ? "0" : "1"}
        transition=".5s all ease"
        pb={buttonProps && isPromoDiscount ? "80px" : "0px"}
      >
        {!useRevampV2 && (
          <>
            <VStack width="full" alignItems="flex-start">
              <Heading
                size="md"
                data-testid="txt_promoku-detail-description-label"
              >
                Deskripsi
              </Heading>
              <Box
                dangerouslySetInnerHTML={{
                  __html:
                    promoData?.description ?? "<p>Tidak ada deskripsi</p>",
                }}
                width="full"
                fontSize="sm"
                data-testid="txtarea_promoku-detail-description"
              />
            </VStack>
            <VStack width="full" alignItems="flex-start">
              <Heading size="md" data-testid="txt_promoku-detail-sk-label">
                Syarat dan Ketentuan
              </Heading>
              <Box
                dangerouslySetInnerHTML={{
                  __html: promoData?.term_and_condition ?? "",
                }}
                fontSize="sm"
                width="full"
                data-testid="txtarea_promoku-detail-sk"
                ml={isTermAndConditionAList ? "15px" : "0"}
              />
            </VStack>
          </>
        )}
        {useRevampV2 && (
          <>
            <VStack width="full" alignItems="flex-start">
              <Heading size="md" data-testid="txt_promoku-detail-sk-label">
                Ketentuan Promo
              </Heading>
              {renderTermAndCondition()}
            </VStack>
            <VStack width="full" alignItems="flex-start">
              <HStack
                justifyContent="space-between"
                paddingY="4px"
                width="full"
                cursor="pointer"
                onClick={() =>
                  setDescSection((prev) => ({
                    ...prev,
                    isExtended: !prev.isExtended,
                  }))
                }
              >
                {!descSection.isExtended && (
                  <>
                    <Heading
                      size="md"
                      data-testid="txt_promoku-detail-desc-label"
                    >
                      Cek Cara Pakai Promo
                    </Heading>
                    <ChevronDownIcon boxSize="30px" />
                  </>
                )}
                {descSection.isExtended && (
                  <>
                    <Heading
                      size="md"
                      data-testid="txt_promoku-detail-desc-label"
                    >
                      Sembunyikan
                    </Heading>
                    <ChevronUpIcon boxSize="30px" />
                  </>
                )}
              </HStack>
              <Box
                dangerouslySetInnerHTML={{
                  __html: promoData?.description ?? "",
                }}
                fontSize="sm"
                width="full"
                height={descSection.isExtended ? undefined : "0"}
                opacity={descSection.isExtended ? "1" : "0"}
                overflow="hidden"
                transition="all ease 0.5s"
                data-testid="txtarea_promoku-detail-desc"
              />
            </VStack>
          </>
        )}
      </VStack>
      {buttonProps && isPromoDiscount && (
        <VStack
          position="absolute"
          bottom="0"
          width="full"
          minHeight="60px"
          {...buttonProps.chakra?.container}
        >
          <Button
            {...buttonProps.chakra?.button}
            data-testid={`btn_footer-button-${encodeString(buttonProps.lable ?? "")}`}
            onClick={() => onUsePromo?.(promoData)}
          >
            {buttonProps.lable}
          </Button>
        </VStack>
      )}
    </VStack>
  );
}
