import { Heading, Image, Text, VStack } from "@efishery/onefish";
import emptyPromoImg from "src/assets/images/empty_promo.svg";
import { ENV } from "src/configs/env";

export function EmptyPromo() {
  return (
    <VStack width="full" flexGrow="1" justifyContent="center" mb="20px">
      <Image
        src={`${ENV.APP_HOST}${emptyPromoImg}`}
        width="full"
        maxH="160px"
        objectFit="contain"
        data-testid="img_promoku-detail-header-image"
      />
      <VStack spacing="16px" mt="32px" px={20}>
        <Heading size="md" textAlign="center">
          Belum Ada Promo yang Bisa Kamu Pakai
        </Heading>
        <Text
          textAlign="center"
          fontWeight="400"
          fontSize="14px"
          color="#777777"
        >
          Kalau sudah ada, nanti daftar promonya akan muncul di sini, ya.
        </Text>
      </VStack>
    </VStack>
  );
}
