import { Button, Divider, HStack, Input } from "@efishery/onefish";
import { useState } from "react";
import { SERVICE } from "src/configs/service";
import { useCreate, useToast } from "src/hooks";
import {
  IReferralOrPromoCodeInputObject,
  IReferralOrPromoCodeInputVariables,
} from "src/interface/promo.model";

import { ICodeInputProps } from "../..";
import {
  codeInputMessage,
  ReferralErrorKeys,
  referralErrorMessages,
} from "../../constants";
import {
  trackResultCodePromoku,
  trackSubmitCodePromoku,
} from "../../pages/List/tracker";

export function CodeInput({ meta = {} }: ICodeInputProps) {
  const [referralOrPromoCode, setReferralOrPromoCode] = useState("");

  // RPC for ReferralOrPromoCode
  const { mutate: mutateRPC, isLoading: mutateRPCLoading } = useCreate<
    IReferralOrPromoCodeInputObject,
    IReferralOrPromoCodeInputVariables
  >({
    apiUrl: SERVICE.RECOMMENDATION,
  });
  const { headers } = meta;
  const authToken = headers?.Authorization;
  const toast = useToast();
  const handleSubmitCode = async () => {
    if (authToken && referralOrPromoCode) {
      trackSubmitCodePromoku(referralOrPromoCode, authToken);
    }
    await mutateRPC({
      resource: "api/v1/referrals/application",
      variables: { code: referralOrPromoCode },
      onSuccess(_data, variables) {
        if (authToken && variables.code) {
          // TODO: Need flag to identify code type between referral or promo on success response
          trackResultCodePromoku(
            {
              type: "referral",
              referralOrPromoCode: variables.code,
              result: "success",
            },
            authToken
          );
        }
        toast({
          title: codeInputMessage.referral.success,
          status: "success",
          duration: 1500,
          position: "top",
        });
      },
      onError(_error, variables) {
        const errorMessage = _error.message as ReferralErrorKeys;
        const errorReason =
          referralErrorMessages[errorMessage] ||
          codeInputMessage.referral.failed;

        if (authToken && variables.code) {
          trackResultCodePromoku(
            {
              type: errorMessage.includes("REFERRAL") ? "referral" : "promo",
              referralOrPromoCode: variables.code,
              result: "failed",
              reason: errorMessage,
            },
            authToken
          );
        }
        toast({
          title: errorReason,
          status: "error",
          duration: 1500,
          position: "top",
        });
      },
      meta: {
        headers,
      },
    });
  };
  return (
    <>
      <HStack alignItems="center" paddingY="16px" spacing="16px">
        <Input
          borderColor="black"
          size="md"
          placeholder="Masukkan Kode"
          value={referralOrPromoCode}
          data-testid="txtfld_promoku_codeinput"
          onChange={(e) =>
            setReferralOrPromoCode(e.currentTarget.value.replace(" ", ""))
          }
        />
        <Button
          size="md"
          fontWeight="500"
          variant="outline"
          data-testid="btn_promoku_codeinput"
          paddingX="24px"
          isLoading={mutateRPCLoading}
          loadingText="Pakai"
          onClick={handleSubmitCode}
          isDisabled={!referralOrPromoCode}
          _disabled={referralOrPromoCode ? undefined : {}}
          _hover={referralOrPromoCode ? undefined : {}}
        >
          Pakai
        </Button>
      </HStack>
      <Divider />
    </>
  );
}
