import { Button, Stack, Text, VStack } from "@efishery/onefish";
import { Loading } from "src/components";
import { IUseListReturnValue } from "src/hooks/dataProvider/type";
import { IPromoData } from "src/interface/promo.model";

import { EmptyPromo, IPromoListProps, PromoCard } from "../..";

export function PromoList({
  chakra,
  onPromoClick,
  queryResults,
}: IPromoListProps) {
  const { data, isLoading, isSuccess, refetch } =
    queryResults as IUseListReturnValue<IPromoData>;
  const renderPromoList = (data: IPromoData[]) => {
    if (data.length <= 0) {
      return <EmptyPromo />;
    }

    return data.map((promo, i) => (
      <PromoCard key={i} promoData={promo} onPromoClick={onPromoClick} />
    ));
  };

  return (
    <Stack
      direction="column"
      width="full"
      spacing="20px"
      padding="10px"
      paddingTop="12px"
      flexGrow="1"
      {...chakra?.container}
    >
      {isLoading && (
        <Loading
          container={{ flexGrow: "1" }}
          spinner={{ color: "brand.500", size: "xl" }}
        />
      )}
      {!isLoading && isSuccess && renderPromoList(data?.data as IPromoData[])}
      {!isLoading && !isSuccess && (
        <VStack width="full" flexGrow="1" justifyContent="center">
          <Text data-testid="txt_promoList-failedMsg">
            Oopss... terjadi kesalahan
          </Text>
          <Button
            size="md"
            variant="outline"
            onClick={refetch}
            data-testid="btn_promoList-refetch"
          >
            Coba Lagi
          </Button>
        </VStack>
      )}
    </Stack>
  );
}
