import { Box, Divider, Stack, Text } from "@efishery/onefish";
import { useMemo } from "react";
import { ErrorGeneral, Loading } from "src/components";
import { FLAGGING_SERVICE } from "src/configs/featuresFlag";
import { IUseListReturnValue } from "src/hooks/dataProvider/type";
import { useFlagging } from "src/hooks/flagging";
import { IPromoData } from "src/interface/promo.model";
import { toCamelCase } from "src/utils/string";

import { CodeInput, EmptyPromo, IPromoListV2Props, PromoCardV2 } from "../..";

export function PromoListV2({
  chakra,
  onPromoClick,
  queryResultsPromos,
  queryResultsPublicPromos,
  meta = {},
}: IPromoListV2Props) {
  const { data: flaggingData } = useFlagging([
    {
      flag: FLAGGING_SERVICE.FEATURE_PROMOKU_REFERRAL,
      defaultValue: { variantKey: "off" },
    },
  ]);
  const [FEATURE_PROMOKU_REFERRAL] = flaggingData;
  const usePromokuReferral = FEATURE_PROMOKU_REFERRAL?.variantKey === "on";
  const {
    data: dataPromos,
    isLoading: isLoadingPromos,
    isSuccess: isSuccessPromos,
    refetch: refetchPromos,
  } = queryResultsPromos as IUseListReturnValue<IPromoData>;

  const {
    data: dataPublicPromos,
    isLoading: isLoadingPublicPromos,
    isSuccess: isSuccessPublicPromos,
    refetch: refetchPublicPromos,
  } = queryResultsPublicPromos as IUseListReturnValue<IPromoData>;

  const isLoading = useMemo(
    () => isLoadingPromos || isLoadingPublicPromos,
    [isLoadingPromos, isLoadingPublicPromos]
  );

  const isDataEmpty = useMemo(
    () => dataPromos?.total === 0 && dataPublicPromos?.total === 0,
    [dataPromos, dataPublicPromos]
  );

  const isSuccess = useMemo(
    () => isSuccessPromos && isSuccessPublicPromos,
    [isSuccessPromos, isSuccessPublicPromos]
  );

  const refetchListPromos = () => {
    refetchPromos();
    refetchPublicPromos();
  };

  const renderPromoList = (label: string, data: IPromoData[]) => {
    if (data.length > 0) {
      return (
        <>
          <Text fontWeight="600" fontSize="16px">
            {label}
          </Text>
          {data.map((promo) => (
            <PromoCardV2
              key={`component_${toCamelCase(label)}_promoCard_${promo.id}`}
              promoData={promo}
              onPromoClick={onPromoClick}
            />
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <Stack w="full" flexGrow={1}>
      <Stack
        flex={1}
        spacing="16px"
        padding="16px"
        paddingTop="12px"
        direction="column"
        background="white"
        {...chakra?.container}
      >
        {isLoading ? (
          <Loading
            container={{ flexGrow: "1" }}
            spinner={{ color: "brand.500", size: "xl" }}
          />
        ) : null}
        {!isLoading && !isSuccess ? (
          <ErrorGeneral refetch={refetchListPromos} />
        ) : null}
        {!isLoading && isSuccess && isDataEmpty ? <EmptyPromo /> : null}
        {!isLoading && isSuccess && !isDataEmpty ? (
          <>
            {meta?.headers?.Authorization && usePromokuReferral && (
              <CodeInput meta={meta} />
            )}
            {renderPromoList("Promo Diskon", dataPromos?.data as IPromoData[])}
            {(dataPublicPromos?.total as number) > 0 &&
            (dataPromos?.total as number) > 0 ? (
              <Box mt={2}>
                <Divider borderBottomWidth={2} />
              </Box>
            ) : null}
            {renderPromoList(
              "Promo Non Diskon",
              dataPublicPromos?.data as IPromoData[]
            )}
          </>
        ) : null}
      </Stack>
    </Stack>
  );
}
