import { VStack } from "@chakra-ui/react";
import {
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@efishery/onefish";
import { Container } from "src/components";

import { IPromoDetailModalProps } from "../..";

export function PromoDetailModal({
  modalDisclosure,
  path,
}: IPromoDetailModalProps) {
  const { isOpen, onClose } = modalDisclosure;
  return (
    <Container>
      <Modal
        blockScrollOnMount={true}
        isOpen={isOpen as boolean}
        onClose={onClose as () => void}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Informasi Promo yang digunakan</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack width="full" padding="14px">
              <VStack width="full">
                <Heading size="md">URL</Heading>
                <Text fontSize="sm" width="full">
                  {path.url}
                </Text>
              </VStack>
              <Divider />
              <VStack width="full">
                <Heading size="md">Query Parameter</Heading>
                <VStack width="full" alignItems="flex-start">
                  {path.params.map((param, i) => (
                    <VStack key={i} alignItems="flex-start" width="full">
                      <Heading size="md">{param.key}</Heading>
                      <Text fontSize="sm">{param.value}</Text>
                    </VStack>
                  ))}
                </VStack>
              </VStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
}
