import { dataLayer } from "src/utils/tracking";

export function trackClickPromoDetail(promoCode: string, authToken: string) {
  const tagManagerProps = {
    dataLayer: {
      event: "btn_see_promo",
      eventCategory: "PromoKu",
      eventLabel: promoCode,
      pageTitle: "PromoKu",
    },
  };
  dataLayer(tagManagerProps, authToken);
}
export function trackPromoKuLoaded(authToken: string) {
  const tagManagerProps = {
    dataLayer: {
      event: "page_promoku",
      eventCategory: "PromoKu",
      eventLabel: "",
      pageTitle: "PromoKu",
    },
  };
  dataLayer(tagManagerProps, authToken);
}

export function trackSubmitCodePromoku(
  referralOrPromoCode: string,
  authToken: string
) {
  const tagManagerProps = {
    dataLayer: {
      event: "submit_code_promoku",
      eventCategory: "Promo",
      pageTitle: "Promoku",
      eventLabel: referralOrPromoCode,
    },
  };
  dataLayer(tagManagerProps, authToken);
}

type ResultCodePromokuValidationProps = {
  referralOrPromoCode: string;
  type: "referral" | "promo";
  result: "success" | "failed";
  reason?: string;
};
export function trackResultCodePromoku(
  validationResult: ResultCodePromokuValidationProps,
  authToken: string
) {
  const { referralOrPromoCode, type, result, reason = "" } = validationResult;
  const tagManagerProps = {
    dataLayer: {
      event: "result_code_promoku",
      eventLabel: `${referralOrPromoCode} - ${type} - ${result} - ${reason}`,
      eventCategory: "Promo",
      pageTitle: "Promoku",
    },
  };
  dataLayer(tagManagerProps, authToken);
}
