import { useEffect, useState } from "react";
import { ENV } from "src/configs/env";
import { SERVICE } from "src/configs/service";
import { axiosInstance } from "src/utils";

import {
  FlagEvalObject,
  FlaggingOptions,
  FlaggingPayload,
  FlaggingResponseData,
} from "./type";

export const useFlagging = (flagsOptions: FlaggingOptions[]) => {
  const reducedData = flagsOptions.reduce(
    (flagEvalObject: FlagEvalObject, flagOption) => {
      const { flag, entityId, entityType, entityContext, defaultValue } =
        flagOption;
      const [stagingFlag, uatFlag, prodFlag] = flag;
      const flagId = () => {
        switch (ENV.APP_ENV) {
          case "production":
            return prodFlag || stagingFlag + 2;
          case "uat":
            return uatFlag || stagingFlag + 1;
          default:
            return stagingFlag;
        }
      };

      const payload: FlaggingPayload = {
        entityID: entityId || `micro_belut_${flagId()}`,
        flagID: flagId(),
        entityType,
        entityContext,
        enableDebug: ENV.APP_ENV !== "production",
      };

      const defaultResult: FlaggingResponseData = {
        flagID: flagId(),
        flagKey: "default_result",
        flagSnapshotID: 0,
        segmentID: 0,
        timestamp: new Date().toISOString(),
        variantAttachment: {},
        variantID: 0,
        variantKey: "default",
        evalContext: {
          flagID: flagId(),
          entityID: entityId || `micro_belut_${flagId()}`,
          entityType,
          entityContext,
          flagKey: "default_result",
        },
        evalDebugLog: {
          segmentDebugLogs: [],
        },
        ...defaultValue,
      };

      const promise = axiosInstance.post(
        `${SERVICE.FLAGGING}/api/v1/evaluation`,
        payload
      );
      flagEvalObject.promisses.push(promise);
      flagEvalObject.defaultResults.push(defaultResult);
      return flagEvalObject;
    },
    { promisses: [], defaultResults: [] }
  );
  const [data, setData] = useState<FlaggingResponseData[]>(
    reducedData.defaultResults
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(true);
  const [isError, setIsError] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const getFlaggingEvaluation = async () => {
    setIsLoading(true);
    try {
      const res = await Promise.all(reducedData.promisses);
      const dataMap = res.map((evalData) => evalData.data);
      setIsSuccess(true);
      setIsError(false);
      setData(dataMap);
      setIsLoading(false);
    } catch (error) {
      setIsSuccess(false);
      setIsError(true);
      setData(reducedData.defaultResults);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFlaggingEvaluation();
  }, [refetch]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch: () => setRefetch(!refetch),
  };
};
