import { dataLayer } from "src/utils/tracking";

export function trackOnUsePromo(promoCode: string, authToken: string) {
    const tagManagerProps = {
        dataLayer: {
            event: 'btn_use_promoku_detail',
            eventCategory: 'PromoKu',
            eventLabel: promoCode,
            pageTitle: "Detail PromoKu"
        },
    };
    dataLayer(tagManagerProps, authToken);
}

export function trackPromoKuDetailsLoaded(promoCode: string, authToken: string) {
    const tagManagerProps = {
        dataLayer: {
            event: 'page_promoku_detail',
            eventCategory: 'PromoKu',
            eventLabel: promoCode,
            pageTitle: "Detail PromoKu"
        },
    };
    dataLayer(tagManagerProps, authToken);
}