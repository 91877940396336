import { Button, Heading, Image, Text, VStack } from "@efishery/onefish";
import { IoRefresh } from "react-icons/io5";
import errorGeneralImg from "src/assets/images/error_general.svg";
import { ENV } from "src/configs/env";

interface IErrorGeneralProps {
  refetch: () => void;
}

export function ErrorGeneral({ refetch }: IErrorGeneralProps) {
  return (
    <VStack width="full" flexGrow="1" justifyContent="center" mb="20px">
      <Image
        src={`${ENV.APP_HOST}${errorGeneralImg}`}
        width="full"
        maxH="250px"
        objectFit="contain"
        data-testid="img_promoku-detail-header-image"
      />
      <VStack spacing={0} mt="-16px">
        <Heading size="md">Mohon Maaf.</Heading>
        <Heading size="md">Telah Terjadi Kesalahan.</Heading>
      </VStack>
      <VStack spacing="12px">
        <Text
          textAlign="center"
          fontWeight="400"
          fontSize="14px"
          color="#777777"
        >
          Silakan kembali ke halaman sebelumnya atau muat ulang.
        </Text>
        <Button
          onClick={refetch}
          leftIcon={<IoRefresh size="20px" />}
          aria-label="Reload Button"
          data-testid="btn_erroGeneral_refetch"
        >
          Muat Ulang
        </Button>
      </VStack>
    </VStack>
  );
}
