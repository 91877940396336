import { IPromoData } from "src/interface/promo.model";

export function promoCardDescription(data: IPromoData) {
  const promoType = data?.rules?.promo_type;
  const promoAmount = data?.rules?.promo_amount;
  const maxDiscount = data?.rules?.maximum_discount;
  const promoUnit = data?.rules?.promo_unit;
  const promoCriteria = data?.rules?.promo_criteria;

  const checkEligible = (criteria: IPromoData["rules"]["promo_criteria"]) => {
    let allEligible = true;
    for (const key in criteria) {
      if (
        criteria[key as keyof IPromoData["rules"]["promo_criteria"]] &&
        (criteria[key as keyof IPromoData["rules"]["promo_criteria"]] as any[])
          .length > 0
      ) {
        allEligible = false;
        break;
      }
    }
    return allEligible;
  };

  const eligibleProduct = (criteria: IPromoData["rules"]["promo_criteria"]) => {
    const productTypes = criteria?.product_types;
    if (productTypes) {
      if (productTypes.length === 1) return `${productTypes[0]}`;
    }
    return "pilihan";
  };

  const isAllEligible = checkEligible(promoCriteria);

  if (promoType === "mf_nonauthorized") {
    return `Potongan pengguna baru Menarik untuk produk pakan`;
  }

  if (promoType === "fixed") {
    if (isAllEligible) {
      if (maxDiscount)
        return `Potongan hingga ${currencyFormat(maxDiscount)} untuk semua jenis produk`;
      if (!maxDiscount)
        return `Potongan ${currencyFormat(promoAmount)} untuk semua jenis produk`;
    }
    if (!isAllEligible) {
      if (maxDiscount)
        return `Potongan hingga ${currencyFormat(maxDiscount)} untuk produk ${eligibleProduct(promoCriteria)}`;
      if (!maxDiscount)
        return `Potongan ${currencyFormat(promoAmount)} untuk produk ${eligibleProduct(promoCriteria)}`;
    }
  }

  if (promoType === "percentage") {
    if (isAllEligible) {
      if (maxDiscount)
        return `Potongan ${numbInputPercentFormatter(promoAmount)} hingga ${currencyFormat(maxDiscount)} untuk semua jenis produk`;
      if (!maxDiscount)
        return `Potongan ${numbInputPercentFormatter(promoAmount)} untuk semua jenis produk`;
    }

    if (!isAllEligible) {
      if (maxDiscount)
        return `Potongan ${numbInputPercentFormatter(promoAmount)} hingga ${currencyFormat(maxDiscount)}  untuk produk ${eligibleProduct(promoCriteria)}`;
      if (!maxDiscount)
        return `Potongan ${numbInputPercentFormatter(promoAmount)} untuk produk ${eligibleProduct(promoCriteria)}`;
    }
  }

  if (promoType === "per-unit") {
    if (isAllEligible) {
      if (maxDiscount)
        return `Potongan ${currencyFormat(promoAmount)} per ${promoUnit} hingga ${currencyFormat(maxDiscount)} untuk semua jenis produk`;
      if (!maxDiscount)
        return `Potongan ${currencyFormat(promoAmount)} per ${promoUnit} untuk semua jenis produk`;
    }

    if (!isAllEligible) {
      if (maxDiscount)
        return `Potongan ${currencyFormat(promoAmount)} per ${promoUnit} hingga ${currencyFormat(maxDiscount)}  untuk produk ${eligibleProduct(promoCriteria)}`;
      if (!maxDiscount)
        return `Potongan ${currencyFormat(promoAmount)} per ${promoUnit} untuk produk ${eligibleProduct(promoCriteria)}`;
    }
  }
}

export const numberInputFormatter = (value: any) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const numberInputParser = (value: any) =>
  value ? value.replace(/\$\s?|(\.*)/g, "") : "";

export const numbInputPercentFormatter = (value: any) =>
  value ? `${value} %` : "";

export const numbInputPercentParser = (value: any) => {
  let result = (value || "").replace(/[^0-9.]/g, "");
  if (result.split(".").length > 2) result = result.replace(/\.+$/, "");
  return result;
};

export const currencyFormat = (val: number) => {
  return val === 0
    ? "Rp -"
    : new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      }).format(val);
};
