import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import PromoKuPageAuthorized from "./PromoKuPageAuthorized";
import PromoKuPageNonAuthorized from "./PromoKuPageNonAuthorized";

interface PromoKuListPageProps {
  flagPromoKuRevamp: boolean;
}

export default function PromoKuListPage({
  flagPromoKuRevamp,
}: PromoKuListPageProps) {
  const navigate = useNavigate();
  const initialResourceProps = {
    Authorization: "",
    GTM_ID: "",
    "X-Client-Id": "efisheryku",
  };
  const [resourceProps, setResourceProps] = useState(initialResourceProps);

  useEffect(() => {
    const authorizationToken = sessionStorage.getItem("dev_promoku_auth");
    const xClientId = sessionStorage.getItem("dev_promoku_clientid");
    const gtmId = sessionStorage.getItem("dev_promoku_gtmid");
    setResourceProps((prev) => ({
      ...prev,
      ...(authorizationToken && { Authorization: authorizationToken }),
      ...(xClientId && { "X-Client-Id": xClientId }),
      ...(gtmId && { GTM_ID: gtmId }),
    }));
  }, []);

  if (resourceProps.Authorization === "") {
    return (
      <PromoKuPageNonAuthorized
        headerProps={{
          title: "PromoKu",
          onPreviousClick() {
            navigate("/");
          },
          chakra: {
            container: {
              marginBottom: "10px",
              bg: "white",
            },
          },
        }}
        resourceProps={{
          gtmID: resourceProps.GTM_ID,
          meta: {
            headers: {
              Authorization: resourceProps.Authorization,
              "X-Client-Id": resourceProps["X-Client-Id"],
            },
          },
        }}
        onPromoClick={() => navigate("/login")}
        useFragmentWrapper
        useOuterContainer
        flagPromoKuRevamp={flagPromoKuRevamp}
      />
    );
  }

  return (
    <PromoKuPageAuthorized
      headerProps={{
        title: "PromoKu",
        onPreviousClick() {
          navigate("/");
        },
        chakra: {
          container: {
            marginBottom: "10px",
            bg: "white",
          },
        },
      }}
      resourceProps={{
        gtmID: resourceProps.GTM_ID,
        meta: {
          headers: {
            Authorization: resourceProps.Authorization,
            "X-Client-Id": resourceProps["X-Client-Id"],
          },
        },
      }}
      onPromoClick={(promo) => navigate(`/demo/promoku/${promo.id}`)}
      useFragmentWrapper
      useOuterContainer
      flagPromoKuRevamp={flagPromoKuRevamp}
    />
  );
}
