import { useEffect, useMemo } from "react";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { FLAGGING_SERVICE } from "src/configs/featuresFlag";
import { SERVICE } from "src/configs/service";
import { useList } from "src/hooks";
import { useFlagging } from "src/hooks/flagging";
import { IPromoData } from "src/interface/promo.model";
import { ErrorResponse } from "src/libs/dataProvider/types";

import { MobileLayout } from "../../../../components";
import { IPromoKuListPageProps, PromoList, PromoListV2 } from "../..";
import { trackClickPromoDetail, trackPromoKuLoaded } from "./tracker";

type PromoQueryResultsType = {
  queryResultPromos: IPromoData[];
  queryResultPublicPromos: IPromoData[];
};

export default function PromoKuPageAuthorized({
  headerProps,
  footerProps,
  chakra,
  resourceProps,
  onPromoClick,
  useOuterContainer,
  useFragmentWrapper,
  flagPromoKuRevamp,
}: IPromoKuListPageProps) {
  const { data: flaggingData } = useFlagging([
    {
      flag: FLAGGING_SERVICE.FEATURE_PROMO_REVAMP_V2,
      defaultValue: { variantKey: "off" },
    },
  ]);
  const [FEATURE_PROMOKUV2] = flaggingData;

  const isPromoKuRevamp = useMemo(() => {
    // for testing purpose
    if (flagPromoKuRevamp !== undefined) return flagPromoKuRevamp;
    return FEATURE_PROMOKUV2.variantKey === "on";
  }, [FEATURE_PROMOKUV2]);

  const queryResults = useList<IPromoData>({
    apiUrl: SERVICE.PROMO_V2,
    resource: "v1/promos",
    meta: {
      ...resourceProps?.meta,
    },
  });

  const { data: promoData, ...resData } = queryResults;
  const queryResultAll = useMemo(() => {
    if ((promoData?.data as ErrorResponse)?.error) {
      return {
        queryResultPromos: [],
        queryResultPublicPromos: [],
      };
    }
    return (promoData?.data as IPromoData[])?.reduce<PromoQueryResultsType>(
      (result, promo) => {
        if (promo.rules?.promo_category === "physical-prize") {
          result.queryResultPublicPromos.push(promo);
        } else if (
          promo.rules?.promo_category === "discount" ||
          promo.rules?.promo_category === undefined
        ) {
          result.queryResultPromos.push(promo);
        }
        return result;
      },
      {
        queryResultPromos: [],
        queryResultPublicPromos: [],
      }
    );
  }, [promoData]);

  if (resourceProps?.gtmID) {
    const tagManagerArgs: TagManagerArgs = {
      gtmId: resourceProps?.gtmID || "",
    };
    TagManager.initialize(tagManagerArgs);
  }

  useEffect(() => {
    if (resourceProps?.gtmID) {
      trackPromoKuLoaded(resourceProps?.meta?.headers?.Authorization || "");
    }
  }, [resourceProps?.gtmID]);

  return (
    <MobileLayout
      chakra={chakra?.layout}
      headerProps={headerProps}
      footerProps={footerProps}
      useOuterContainer={useOuterContainer}
      useFragmentWrapper={useFragmentWrapper}
    >
      {isPromoKuRevamp ? (
        <PromoListV2
          queryResultsPromos={{
            ...resData,
            data: {
              data: queryResultAll?.queryResultPromos || [],
              total: queryResultAll?.queryResultPromos?.length || 0,
            },
          }}
          queryResultsPublicPromos={{
            ...resData,
            data: {
              data: queryResultAll?.queryResultPublicPromos || [],
              total: queryResultAll?.queryResultPublicPromos?.length || 0,
            },
          }}
          onPromoClick={(promoData) => {
            if (resourceProps?.gtmID) {
              trackClickPromoDetail(
                promoData.code,
                resourceProps?.meta?.headers?.Authorization || ""
              );
            }
            onPromoClick?.(promoData);
          }}
          chakra={chakra?.promoKuList}
          meta={resourceProps?.meta}
        />
      ) : (
        <PromoList
          queryResults={queryResults}
          onPromoClick={(promoData) => {
            if (resourceProps?.gtmID) {
              trackClickPromoDetail(
                promoData.code,
                resourceProps?.meta?.headers?.Authorization || ""
              );
            }
            onPromoClick?.(promoData);
          }}
          chakra={chakra?.promoKuList}
        />
      )}
    </MobileLayout>
  );
}
