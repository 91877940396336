import { HStack, Image, Text, VStack } from "@efishery/onefish";
import { PiMoney } from "react-icons/pi";

import { ITransactionLimitProps } from "../..";
import { currencyFormat } from "../../utils";

export function TransactionLimit({ data }: ITransactionLimitProps) {
  const rules = data?.rules;
  const minTransactionAmount = rules?.minimum_transaction_amount;
  const minTransactionQuantity = rules?.minimum_quantity;
  const transactionUnit = rules?.transaction_unit;

  if (transactionUnit && transactionUnit !== "rupiah") {
    return (
      <HStack mr="20px">
        <Image
          src=""
          fallback={<PiMoney color="black" size="20px" />}
          boxSize="30px"
          data-testid="img_promoku-detail-transactionLimit"
        />
        <VStack alignItems="flex-start" spacing="0">
          <Text
            fontSize="xs"
            fontWeight="700"
            color="grey.400"
            data-testid="txt_promoku-detail-transactionLimit-label"
          >
            Min. Transaksi
          </Text>
          <Text
            fontSize="xs"
            data-testid="txt_promoku-detail-transactionLimit-value"
          >
            {`${minTransactionQuantity || minTransactionAmount} ${transactionUnit}`}
          </Text>
        </VStack>
      </HStack>
    );
  }

  if (!transactionUnit && minTransactionAmount) {
    return (
      <HStack mr="20px">
        <Image
          src=""
          fallback={<PiMoney color="black" size="20px" />}
          boxSize="30px"
          data-testid="img_promoku-detail-transactionLimit"
        />
        <VStack alignItems="flex-start" spacing="0">
          <Text
            fontSize="xs"
            fontWeight="700"
            color="grey.400"
            data-testid="txt_promoku-detail-transactionLimit-label"
          >
            Min. Transaksi
          </Text>
          <Text
            fontSize="xs"
            data-testid="txt_promoku-detail-transactionLimit-value"
          >
            {currencyFormat(data?.rules?.minimum_transaction_amount ?? 0)}
          </Text>
        </VStack>
      </HStack>
    );
  }

  return null;
}
