import { Image } from "@chakra-ui/react";
import {
  Button,
  Divider,
  Flex,
  Heading,
  Text,
  VStack,
} from "@efishery/onefish";
import dayjs from "dayjs";

import PromoIcon from "../../../../assets/icons/ic_promo.svg";
import { IPromoCardProps } from "../..";
import { promoCardDescription } from "../../utils";

export function PromoCard({
  promoData,
  chakra,
  onPromoClick,
}: IPromoCardProps) {
  const promoRules = promoData.rules;

  return (
    <VStack
      bg="white"
      boxShadow="0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06)"
      align="center"
      borderRadius="10px"
      width="full"
      padding="14px"
      {...chakra?.container}
    >
      <VStack spacing="6px" width="full">
        <Flex
          justifyContent="space-between"
          columnGap="10px"
          width="full"
          _hover={{
            cursor: "pointer",
          }}
          onClick={() => onPromoClick?.(promoData)}
          data-testid={`btn_card_promoCard-goToDetail-${promoData.id}`}
        >
          <VStack alignItems="flex-start">
            <Heading
              as="h1"
              size="16px"
              fontWeight="semibold"
              noOfLines={2}
              data-testid={`txt_promoCard-title-${promoData.id}`}
            >
              {promoData.name}
            </Heading>
            <Text
              mt="4px!important"
              fontSize="xs"
              noOfLines={2}
              data-testid={`txt_promoCard-topic-${promoData.id}`}
            >
              {promoCardDescription(promoData)}
            </Text>
          </VStack>
          <Image
            src={promoData?.image_url}
            fallbackSrc={PromoIcon}
            boxSize="56px"
            data-testid={`img_promoCard-banner-${promoData.id}`}
          />
        </Flex>
        <Divider />
        <Flex justifyContent="space-between" width="full">
          <VStack alignItems="flex-start" spacing="0">
            <Text
              fontSize="xs"
              fontWeight="700"
              color="grey.400"
              data-testid={`txt_promoCard-expiredTime-label-${promoData.id}`}
            >
              {promoRules.promo_type === "mf_nonauthorized"
                ? ""
                : "Berlaku Sampai"}
            </Text>
            <Text
              fontSize="xs"
              data-testid={`txt_promoCard-expiredTime-date-${promoData.id}`}
            >
              {promoRules.promo_type === "mf_nonauthorized"
                ? ""
                : `${dayjs
                    .utc(promoRules?.end_date)
                    .tz("Asia/Jakarta")
                    .locale("id")
                    .format("DD MMMM YYYY - HH.mm")} WIB`}
            </Text>
          </VStack>
          <Button
            variant="link"
            fontWeight="semibold"
            color="brand.500"
            onClick={() => onPromoClick?.(promoData)}
            data-testid={`btn_promoCard-goToDetail-${promoData.id}`}
          >
            Lihat
          </Button>
        </Flex>
      </VStack>
    </VStack>
  );
}
